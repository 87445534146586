<template>
	<FullPageLoader v-if="isLoading" />
	<HeaderTestSiteWarning />
	<HeaderSiteNotification />

	<HeaderBasicHeader />

	<!-- Page content -->
	<slot />

	<FooterBasicFooter />
</template>

<script setup>
	import FullPageLoader from "~/components/fullPageLoader.vue"

	const runtimeConfig = useRuntimeConfig()

	let isLoading = ref(true)
	onMounted(async () => {
		await nextTick()

		// Use requestAnimationFrame to wait for the next paint
		requestAnimationFrame(() => {
			// Use a small timeout to allow any async operations to complete
			setTimeout(() => {
				isLoading.value = false
			}, 500)
		})
	})

	useHead(() => {
		//Prefix all page titles with the event name
		return {
			titleTemplate: (pageTitle) => {
				return pageTitle ? `${runtimeConfig.public.EVENT_NAME} - ${pageTitle}` : `${runtimeConfig.public.EVENT_NAME}`
			},
		}
	})
</script>

<style scoped></style>
