<template>
	<div class="fixed flex items-center justify-center z-50 w-screen h-screen bg-loader-bg">
		<div class="rounded-lg bg-black mx-auto loadingPopupComponent">
			<img
				:src="runtimeConfig.public.DEFAULT_LOADER"
				alt="Loading..." />
		</div>
	</div>
</template>

<script setup>
	const runtimeConfig = useRuntimeConfig()
</script>

<style scoped></style>
